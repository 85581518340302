import React, { Component } from 'react'
import './Rental.css'

export default class Rental extends Component {
    render() {
        return (
            <div>
                {/* <button>Add</button> */}
            </div>
        )
    }
}